import React from "react";

import Layout from "../components/Layout";
import PenIcon from "../components/PenIcon";
import DotIcon from "../components/DotIcon";

import AnalysisSVG from "../assets/analyse.svg";
import DevSVG from "../assets/dev.svg";
import BiostatSVG from "../assets/biostat.svg";
import AnalyseSpeSVG from "../assets/analysespe.svg";
import ConseilSVG from "../assets/conseil.svg";

function Services() {
  return (
    <Layout title="Services">
      <h2 className="text-4xl font-bold leading-none text-center lg:text-6xl">
        KerNel for <span className="text-orange-500">Analysis</span>
      </h2>
      <section className="container w-screen mx-auto x-6">
        <div className="relative w-full p-4 mx-auto">
          <div className="lg:grid lg:grid-flow-row-dense lg:grid-cols-2 lg:gap-12 lg:items-center">
            <div className="lg:col-start-2 md:pl-20">
              <ul className="mt-10">
                <li>
                  <div className="flex">
                    <PenIcon />
                    <div className="ml-4">
                      <h5 className="text-lg font-bold leading-6 text-gray-900">
                        Nos thématiques
                      </h5>
                      <ul className="mt-2 text-base leading-6 text-gray-500">
                        <li>Données de santé et signaux biomédicaux</li>
                        <li>Toutes spécialités médicales</li>
                      </ul>
                    </div>
                  </div>
                </li>
                <li className="mt-10">
                  <div className="flex">
                    <PenIcon />
                    <div className="ml-4">
                      <h5 className="text-lg font-bold leading-6 text-gray-900">
                        Nos services
                      </h5>
                      <ul className="mt-2 text-base leading-6 text-gray-500">
                        <li>Création d'algorithmes d'analyses spécifiques</li>
                        <li>Développement d'applications (logiciels, sites)</li>
                        <li>Biostatistiques</li>
                      </ul>
                    </div>
                  </div>
                </li>
                <li className="mt-10">
                  <div className="flex">
                    <PenIcon />
                    <div className="ml-4">
                      <h5 className="text-lg font-bold leading-6 text-gray-900">
                        Pour qui ?
                      </h5>
                      <ul className="mt-2 text-base leading-6 text-gray-500">
                        <li>Chercheurs</li>
                        <li>Professionnels de santé et paramédicaux</li>
                        <li>Prestataires de santé</li>
                        <li>Fabricants de DMs</li>
                      </ul>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
            <div class="mt-10 -mx-4 md:-mx-12 relative lg:mt-0 lg:col-start-1">
              <AnalysisSVG className="relative w-1/2 mx-auto" />
            </div>
          </div>
        </div>
      </section>
      <section className="py-8 bg-orange-100">
        <h2 className="my-8 text-4xl font-bold text-center lg:text-5xl">
          Algorithmes{" "}
          <span className="text-orange-500">d'analyses spécifiques</span>
        </h2>
        <div className="container px-6">
          <div className="relative p-4">
            <div className="lg:grid lg:grid-flow-row-dense lg:grid-cols-2 lg:gap-12 lg:items-center">
              <div className="lg:col-start-1 md:pl-20">
                <ul className="mt-10">
                  <li>
                    <div className="flex">
                      <DotIcon />
                      <div className="ml-4">
                        <h5 className="text-lg font-bold leading-6 text-gray-900">
                          Nos atouts
                        </h5>
                        <ul className="mt-2 text-base leading-6 text-gray-500">
                          <li className="my-1">
                            Élaboration de méthodes de calculs innovantes
                          </li>
                          <li className="my-1">
                            Expertise en traitement du signal
                          </li>
                          <li className="my-1">Machine learning, AI</li>
                        </ul>
                      </div>
                    </div>
                  </li>
                  <li className="mt-10">
                    <div className="flex">
                      <DotIcon />
                      <div className="ml-4">
                        <h5 className="text-lg font-bold leading-6 text-gray-900">
                          Exemples de réalisation
                        </h5>
                        <ul className="mt-2 text-base leading-6 text-gray-500">
                          <li className="my-1">
                            Analyse de la cinétique des masques de ventilation
                            non invasive
                          </li>
                          <li className="my-1">
                            Détection des exacerbations de BPCO à l'aide de
                            méthodes d'analyse non linéaires
                          </li>
                        </ul>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
              <div class="mt-10 -mx-4 md:-mx-12 relative lg:mt-0 lg:col-start-2">
                <AnalyseSpeSVG className="relative w-1/2 mx-auto" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="py-6">
        <h2 className="my-4 text-4xl font-bold text-center sm:text-5xl">
          Développement d'applications logicielles{" "}
          <span className="text-orange-500">sur mesure</span>
        </h2>
        <section className="container px-6">
          <div className="relative p-4">
            <div className="lg:grid lg:grid-flow-row-dense lg:grid-cols-2 lg:gap-12 lg:items-center">
              <div className="lg:col-start-2 md:pl-20">
                <ul className="mt-10">
                  <li>
                    <div className="flex">
                      <DotIcon />
                      <div className="ml-4">
                        <h5 className="text-lg font-bold leading-6 text-gray-900">
                          Nos prestations
                        </h5>
                        <ul className="mt-2 text-base leading-6 text-gray-500">
                          <li className="my-1">
                            Création d'interfaces pour la visualisation et
                            l'annotation de données
                          </li>
                          <li className="my-1">
                            Intégration de méthodes de calculs automatisés
                            (scores, indicateurs spécifiques)
                          </li>
                          <li className="my-1">
                            Élaboration d'applications bureau ou cloud pour la
                            recherche et la santé
                          </li>
                          <li className="my-1">
                            Développement de sites internet
                          </li>
                        </ul>
                      </div>
                    </div>
                  </li>
                  <li className="mt-10">
                    <div className="flex">
                      <DotIcon />
                      <div className="ml-4">
                        <h5 className="text-lg font-bold leading-6 text-gray-900">
                          Exemples de réalisation
                        </h5>
                        <ul className="mt-2 text-base leading-6 text-gray-500">
                          <li className="my-1">Logiciel NIRS / FreeO2</li>
                          <li className="my-1">Dotter</li>
                        </ul>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
              <div class="mt-10 -mx-4 md:-mx-12 relative lg:mt-0 lg:col-start-1">
                <DevSVG className="relative w-1/2 mx-auto" />
              </div>
            </div>
          </div>
        </section>
      </div>
      <section className="py-8 bg-orange-100">
        <h2 className="my-8 text-4xl font-bold text-center lg:text-5xl">
          Biostatistiques
        </h2>
        <div className="container px-6">
          <div className="relative p-4">
            <div className="lg:grid lg:grid-flow-row-dense lg:grid-cols-2 lg:gap-12 lg:items-center">
              <div className="lg:col-start-1 md:pl-20">
                <ul className="mt-10">
                  <li>
                    <div className="flex">
                      <DotIcon />
                      <div className="ml-4">
                        <h5 className="text-lg font-bold leading-6 text-gray-900">
                          Nos prestations
                        </h5>
                        <ul className="mt-2 text-base leading-6 text-gray-500">
                          <li className="my-1">
                            Validation de plans d'analyse statistique
                          </li>
                          <li className="my-1">
                            Méthodes de biostatistiques avancées
                          </li>
                          <li className="my-1">
                            Aide à la rédaction d'articles scientifiques
                          </li>
                          <li className="my-1">
                            Toutes spécialités médicales et biomédicales
                          </li>
                        </ul>
                      </div>
                    </div>
                  </li>
                  <li className="mt-10">
                    <div className="flex">
                      <DotIcon />
                      <div className="ml-4">
                        <h5 className="text-lg font-bold leading-6 text-gray-900">
                          Exemples de réalisation
                        </h5>
                        <ul className="mt-2 text-base leading-6 text-gray-500">
                          <li className="my-1">Montre télémédecine</li>
                          <li className="my-1">Étude gynéco</li>
                        </ul>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
              <div class="mt-10 -mx-4 md:-mx-12 relative lg:mt-0 lg:col-start-2">
                <BiostatSVG className="relative w-1/2 mx-auto" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="py-6">
        <h2 className="my-4 text-4xl font-bold text-center sm:text-5xl">
          Conseils
        </h2>
        <section className="container px-6">
          <div className="relative p-4">
            <div className="lg:grid lg:grid-flow-row-dense lg:grid-cols-2 lg:gap-12 lg:items-center">
              <div className="lg:col-start-2 md:pl-20">
                <ul className="mt-10">
                  <li>
                    <div className="flex">
                      <DotIcon />
                      <div className="ml-4">
                        <h5 className="text-lg font-bold leading-6 text-gray-900">
                          Domaines
                        </h5>
                        <ul className="mt-2 text-base leading-6 text-gray-500">
                          <li className="my-1">
                            Recherche clinique (méthodologie, réglementaire)
                          </li>
                          <li className="my-1">
                            Dispositifs médicaux et connectés
                          </li>
                          <li className="my-1">
                            Applications et données de santé
                          </li>
                        </ul>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
              <div class="mt-10 -mx-4 md:-mx-12 relative lg:mt-0 lg:col-start-1">
                <ConseilSVG className="relative w-1/2 mx-auto" />
              </div>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  );
}

export default Services;
