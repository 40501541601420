import React from "react";

function DotIcon() {
  return (
    <div className="flex-shrink-0 mt-1">
      <div className="flex items-center justify-center w-4 h-4 text-white bg-orange-500 rounded-xl"></div>
    </div>
  );
}

export default DotIcon;
